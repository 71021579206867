import React from "react";
import { Layout, PostCard, Pagination } from "../components/common";
import { MetaData } from "../components/common/meta";

class BlogPostsPageTemplate extends React.Component {
    render() {
        return (
            <>
                <MetaData location={this.props.location} />
                <div className="w-75 mx-auto mt-3">
                    <h1 className="special-heading ">{this.props.title}</h1>
                    <hr />
                </div>
                <section className="post-feed w-75 mx-auto">
                    {this.props.posts.map(({ node }) => (
                        // The tag below includes the markup for each post - components/common/PostCard.js
                        <PostCard
                            key={node.id}
                            post={node}
                            isEditable={this.props.isEditable}
                        />
                    ))}
                </section>
                <Pagination pageContext={this.props.pageContext} />
            </>
        );
    }
}

export default BlogPostsPageTemplate;
